<template>
  <i-container v-if="response" class="_margin-bottom-6 bock--container">
    <i-row>
      <i-column
        xs="12"
        sm="12"
        md="12"
        lg="6"
        xl="6"
        v-for="(item, index) in response.data.body"
        :key="`piece-${index}`"
      >
        <prismic-image
          v-if="item.primary.thumb.url"
          :field="item.primary.thumb"
          class="image -responsive bock--hover"
          @click="showVideo(item)"
        />
        <!-- <div
          class="video-link"
          @click="
            $router.push({
              name: 'Video',
              params: { id: slugify(item.primary.title) },
            })
          "
        > -->
        <div>
          <div
            class="
              _padding-top-0
              _margin-bottom-1
              _display-flex
              _justify-content-space-between
            "
          >
            <div>
              <small class="_text-muted _font-weight-semibold bock--trunc">
                {{ item.primary.title }}
              </small>
            </div>
            <div>
              <small
                class="
                  _text-muted
                  _font-weight-semibold
                  bock--trunc
                  _font-italic
                "
              >
                {{ item.primary.year }}
              </small>
            </div>
          </div>
        </div>
      </i-column>
    </i-row>
    <i-modal class="zoom" v-model="visible">
      <div class="_embed _embed-16by9">
        <iframe
          v-if="visible"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
          width="100%"
          class="bock--frame"
          type="text/html"
          :src="urlify({ id: video, type: player })"
          allowfullscreen
        ></iframe>
      </div>
      <i-button
        size="sm"
        link
        variant="primary"
        @click="visible = false"
        class="_padding-left-0 _margin-top-1"
        >Close</i-button
      >
    </i-modal>
    <!-- <pre v-if="response" v-html="JSON.stringify(response, null, 2)"></pre> -->
  </i-container>
</template>

<script>
export default {
  name: "Videos",
  data() {
    return {
      response: null,
      video: null,
      player: null,
      visible: false,
    };
  },
  methods: {
    async getContent() {
      const response = await this.$prismic.client.getSingle("videos");
      this.response = response;
    },
    showVideo(video) {
      this.video = video.primary.video_id;
      this.player = video.primary.player;
      this.visible = true;
    },
    slugify(string) {
      const a =
        "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;";
      const b =
        "aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------";
      const p = new RegExp(a.split("").join("|"), "g");
      return string
        .toString()
        .toLowerCase()
        .replace(/\s+/g, "-") // Replace spaces with -
        .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
        .replace(/&/g, "-and-") // Replace & with 'and'
        .replace(/[^\w-]+/g, "") // Remove all non-word characters
        .replace(/--+/g, "-") // Replace multiple - with single -
        .replace(/^-+/, "") // Trim - from start of text
        .replace(/-+$/, ""); // Trim - from end of text
    },
    urlify(video) {
      let url = "";
      if (video.type === "YouTube") {
        url = `https://www.youtube.com/embed/${video.id}?loop=1&autoplay=0&mute=0&fs=1&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&modestbranding`;
      } else {
        url = `https://player.vimeo.com/video/${video.id}?autoplay=0&&muted=0&loop=1&color=ffffff&byline=0`;
      }
      return url;
    },
  },
  created() {
    this.getContent();
  },
};
</script>

<style lang="scss">
.video-link:hover {
  cursor: pointer;
}
.bock--frame {
  // height: 100vh;
}
</style>
